import { number, object, string } from "yup";
import { unitShortLabel } from "../../../utils/schemaUtils";
import { formatNumber } from "../../../utils/format";

export const productOffFarmProcessingSchema = function (
    { surveyParams, farm },
    { selectOptions, getOption, defaultUnit }
) {
    const group = "product_off_farm_processing";
    const unitSystem = surveyParams?.UNIT_SYSTEM;

    const data = object({
        product_specification: string().notRequired(),
        raw_material_specification: string().notRequired(),
        raw_material_amount: number().min(0.001).nanToUndefined().required(),
        raw_material_amount__unit: string().oneOfOptions("weight").required(),
        water_content_raw_material: number().min(0).max(100).required(),
        amount_main_product: number().min(0.0000001).required(),
        amount_main_product__unit: string().oneOfOptions("weight").required(),
        main_product_yield: number().min(0.00001).max(100).required(),
        coproducts_yield: number().min(0).max(100).required(),
        water_loss_share_raw_material: number().min(0).max(100).required(),
        waste_share_raw_material: number().min(0).max(100).required(),
        waste_disposal: string()
            .oneOfOptions("waste_disposal_method", true)
            .requiredWhen("waste_share_raw_material", (v) => v > 0),
        other_products: string()
            .required()
            .oneOfOptions(selectOptions("yes_no")),
        other_products_description: string().requiredWhen(
            "other_products",
            (v) => v == "yes"
        ),
        total_amount_other_products: number()
            .min(0)
            .nanToUndefined()
            .notRequired()
            .requiredWhen("other_products", (v) => v == "yes"),
        revenue_share_main_product: number()
            .min(0.001)
            .max(100)
            .nanToUndefined()
            .notRequired()
            .requiredWhen("coproducts_yield", (v) => v > 0), // how to make requiredWhen with two conditions?
        revenue_share_coproducts: number()
            .min(0)
            .max(100)
            .nanToUndefined()
            .notRequired()
            .requiredWhen("coproducts_yield", (v) => v > 0),
        revenue_share_all_other_products: number()
            .min(0)
            .max(100)
            .nanToUndefined()
            .notRequired()
            .requiredWhen("other_products", (v) => v == "yes"),
    });
    const defaults = {
        raw_material_amount__unit: defaultUnit("weight", unitSystem).name,
        amount_main_product__unit: defaultUnit("weight", unitSystem).name,
    };
    const ui = {
        type: "section",
        name: group,
        //label__de: "Produkt & Allokation",
        label: "Product & Allocation",
        children: [
            {
                type: "note",
                label: "Please note that all the data provided should be for production/ processing taking place at the facility **within {{year}}**.",
                styleBreakRowAfter: true,
            },
            {
                type: "text",
                name: `${group}.product_specification`,
                label: "Product specification for {{crop_name}}",
                hint: "This is considered the main product of this survey",
                info: {
                    title: "Product specification",
                    text: "If you want to differentiate the product assessed with this survey from similar products, you can provide a short description stating some of the properties of the product of interest here.",
                },
                widthLg: 5,
                styleBreakRowAfter: true,
            },
            {
                type: "note",
                label: "Input",
                styleClassName: "fs-3 mt-4",
                styleBreakRowAfter: true,
            },
            {
                type: "text", // In der Surveyerstellung festlegen --> Freitextfeld Raw Material (Vitus) --> hier dann nur eine Note, um welches Raw Material es geht
                name: `${group}.raw_material_specification`,
                label: "Raw material ({{raw_material}}) specification",
                //label__de: "Rohstoff",
                hint: "...from which the specified main product is obtained.",
                info: {
                    title: "Raw material specification",
                    text: "If you want to differentiate the raw material used to produce the assessed main products, you can provide a short description stating some of the properties of the product of interest here (such as origin, variety, water content, size etc.).",
                },
                widthLg: 5,
            },
            {
                type: "number",
                name: `${group}.raw_material_amount`,
                label: "Amount of raw material ({{raw_material}})", // general hint at very top of form that all data should be provided for the selected period
                hint: "...purchased and processed in specified time period",
                unit: selectOptions("weight"),
                widthLg: 5,
            },
            {
                type: "number",
                name: `${group}.water_content_raw_material`,
                label: "Water content of raw material {{raw_material}} as it is received",
                label__de: "Wassergehalt des Rohstoffs bei Anlieferung",
                hint: "Example: 40% for Apples that have already been partially dried on-farm before transport to this facility.",
                unit: "%",
                widthLg: 5,
                styleBreakRowAfter: true,
            },
            {
                type: "note",
                label: "Output",
                styleClassName: "fs-3 mt-4",
                styleBreakRowAfter: true,
            },
            {
                type: "number",
                name: `${group}.amount_main_product`,
                label: "Amount of {{crop_name}} produced in specified time period",
                unit: selectOptions("weight"),
                widthLg: 5,
                styleBreakRowAfter: true,
            },
            {
                type: "number",
                name: `${group}.main_product_yield`,
                label: "Product yield {{crop_name}} from raw material",
                hint: "Raw material = {{raw_material}}",
                unit: "%",
                widthLg: 3,
                checkText:
                    "Derived total raw material amount needed to produce main product:",
                checkValue: (watch) => {
                    const productAmount =
                        parseFloat(watch(`${group}.amount_main_product`)) || 0;
                    const productAmountUnit = unitShortLabel(
                        getOption(
                            "weight",
                            watch(`${group}.amount_main_product__unit`)
                        )
                    );
                    const productYieldShare =
                        parseFloat(watch(`${group}.main_product_yield`)) /
                            100 || 0;

                    if (productYieldShare == 0) return "-";

                    const totalRawMaterialAmount =
                        productAmount / productYieldShare;

                    return `${formatNumber(
                        totalRawMaterialAmount,
                        2
                    )} ${productAmountUnit}`;
                },
            },
            {
                type: "number",
                name: `${group}.coproducts_yield`,
                label: "Coproducts yield from raw material",
                unit: "%",
                widthLg: 3,
            },
            {
                type: "number",
                name: `${group}.waste_share_raw_material`,
                label: "Mass share waste from raw material",
                unit: "%",
                widthLg: 3,
            },
            {
                type: "number",
                name: `${group}.water_loss_share_raw_material`,
                label: "Mass of raw material lost as water", // include {{raw_material}} later
                hint: "E.g. water loss through drying)",
                unit: "%",
                widthLg: 3,
                styleBreakRowAfter: true,
                checkText: "Sum of the four weight fractions",
                checkValue: (watch) => {
                    const sum =
                        (parseFloat(watch(`${group}.main_product_yield`)) ||
                            0) +
                        (parseFloat(watch(`${group}.coproducts_yield`)) || 0) +
                        (parseFloat(
                            watch(`${group}.waste_share_raw_material`)
                        ) || 0) +
                        (parseFloat(
                            watch(`${group}.water_loss_share_raw_material`)
                        ) || 0);
                    return `${formatNumber(sum, 2)}%`;
                },
            },
            {
                type: "note",
                label: "**Warning**: Please make sure that the sum of weight fractions (product yield, coproduct yield, weight loss as waste and weight loss as water/vapor) from the raw material add up to 100%!",
                styleClassName: "text-danger",
                styleBreakRowAfter: true,
                condition: (watch) => {
                    const sum =
                        (parseFloat(watch(`${group}.main_product_yield`)) ||
                            0) +
                        (parseFloat(watch(`${group}.coproducts_yield`)) || 0) +
                        (parseFloat(
                            watch(`${group}.waste_share_raw_material`)
                        ) || 0) +
                        (parseFloat(
                            watch(`${group}.water_loss_share_raw_material`)
                        ) || 0);

                    return sum != 0 && (sum <= 99 || sum >= 101);
                },
            },
            {
                type: "select_one",
                name: `${group}.waste_disposal`,
                label: "How do you dispose of the waste?",
                options: selectOptions("waste_disposal_method"),
                widthLg: 5,
                styleBreakRowAfter: true,
                condition: (watch) =>
                    watch(`${group}.waste_share_raw_material`) > 0,
            },

            {
                type: "note",
                label: "Other products",
                styleClassName: "fs-3 mt-4",
                styleBreakRowAfter: true,
            },
            {
                type: "select_one",
                name: `${group}.other_products`,
                label: "Do you produce other products - aside from {{crop_name}} and its coproducts - at this facility?",
                options: selectOptions("yes_no"),
                widthLg: 6,
                styleBreakRowAfter: true,
                styleSelectHorizontalButtons: true,
            },
            {
                type: "text",
                name: `${group}.other_products_description`,
                label: "Description of other product(s)",
                widthLg: 5,
                condition: (watch) => watch(`${group}.other_products`) == "yes",
            },
            {
                type: "number",
                name: `${group}.total_amount_other_products`,
                label: "Total amount of all other products produced",
                unit: selectOptions("weight"),
                widthLg: 5,
                condition: (watch) => watch(`${group}.other_products`) == "yes",
                styleBreakRowAfter: true,
            },
            {
                type: "note",
                label: "Economic allocation",
                styleClassName: "fs-3 mt-4",
                styleBreakRowAfter: true,
                condition: (watch) =>
                    watch(`${group}.coproducts_yield`) > 0 ||
                    watch(`${group}.other_products`) == "yes",
            },
            {
                type: "note",
                label: "The following fields on revenue shares refer to the total **revenue generated from selling all products produced at this facility** in the specified year.",
                styleBreakRowAfter: true,
                condition: (watch) =>
                    watch(`${group}.coproducts_yield`) > 0 ||
                    watch(`${group}.other_products`) == "yes",
            },
            {
                type: "number",
                name: `${group}.revenue_share_main_product`,
                label: "Revenue share of {{crop_name}}",
                unit: "%",
                //styleBreakRowAfter: true,
                widthLg: 4,
                condition: (watch) =>
                    watch(`${group}.coproducts_yield`) > 0 ||
                    watch(`${group}.other_products`) == "yes",
            },
            {
                type: "number",
                name: `${group}.revenue_share_coproducts`,
                label: "Revenue share of coproducts",
                hint: "...from producing {{crop_name}}",
                unit: "%",
                //styleBreakRowAfter: true,
                widthLg: 4,
                condition: (watch) => watch(`${group}.coproducts_yield`) > 0,
            },
            {
                type: "number",
                name: `${group}.revenue_share_all_other_products`,
                //hint: "This should include both other products besides produced from the same raw material and from other raw materials",
                label: "Revenue share of all other products",
                unit: "%",
                styleBreakRowAfter: true,
                widthLg: 4,
                condition: (watch) => watch(`${group}.other_products`) == "yes",
            },
            {
                type: "check_info",
                widthLg: 12,
                checkText: "Sum of all revenue fractions",
                checkValue: (watch) => {
                    const productShare =
                        parseFloat(
                            watch(`${group}.revenue_share_main_product`)
                        ) || 0;
                    const coProductShare =
                        (parseFloat(watch(`${group}.coproducts_yield`)) > 0 &&
                            parseFloat(
                                watch(`${group}.revenue_share_coproducts`)
                            )) ||
                        0;
                    const otherProductsShare =
                        (watch(`${group}.other_products`) == "yes" &&
                            parseFloat(
                                watch(
                                    `${group}.revenue_share_all_other_products`
                                )
                            )) ||
                        0;
                    const sum =
                        productShare + coProductShare + otherProductsShare;
                    return `${formatNumber(sum, 2)}%`;
                },
            },
            {
                type: "note",
                label: "**Warning**: Please make sure that the sum of revenue fractions (product, coproducts and all other products) add up to 100%!",
                styleClassName: "text-danger",
                styleBreakRowAfter: true,
                condition: (watch) => {
                    const productShare =
                        parseFloat(
                            watch(`${group}.revenue_share_main_product`)
                        ) || 0;
                    const coProductShare =
                        (parseFloat(watch(`${group}.coproducts_yield`)) > 0 &&
                            parseFloat(
                                watch(`${group}.revenue_share_coproducts`)
                            )) ||
                        0;
                    const otherProductsShare =
                        (watch(`${group}.other_products`) == "yes" &&
                            parseFloat(
                                watch(
                                    `${group}.revenue_share_all_other_products`
                                )
                            )) ||
                        0;
                    const sum =
                        productShare + coProductShare + otherProductsShare;

                    return sum != 0 && (sum <= 99 || sum >= 101);
                },
            },
        ],
    };

    return { group, data, ui, defaults };
};
