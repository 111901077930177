import { object, string, number } from "yup";
import { formatNumber } from "../../../utils/format";
import { unitShortLabel } from "../../../utils/schemaUtils";

export const residueManagementSugarcaneSchema = function ({}, { getOption }) {
    const group = "residue_management_sugarcane";

    const data = object({
        cane_burned_preharvest_fraction: number().required().min(0).max(100),
        residue_burned_share: number()
            .min(0)
            .max(100)
            .nanToUndefined()
            .notRequired(),
        residue_left_on_field_share: number()
            .min(0)
            .max(100)
            .nanToUndefined()
            .notRequired(),
        residue_composted_share: number()
            .min(0)
            .max(100)
            .nanToUndefined()
            .notRequired(),
    });

    const ui = {
        type: "section",
        name: group,
        label: "Cane burning and trash management",
        label__de: "Erntereste",
        children: [
            {
                type: "note",
                label: "Cane burning before harvest",
                styleClassName: "fs-4",
                widthLg: 12,
            },
            {
                type: "number",
                name: `${group}.cane_burned_preharvest_fraction`,
                label: "What fraction of cane was burned before harvest (black cane harvest)?",
                unit: "%",
                widthLg: 3,
            },
            {
                type: "check_info",
                widthLg: 2,
                checkText: "Black cane harvest area",
                checkValue: (watch) => {
                    const replantedAreaFraction =
                        parseFloat(
                            watch(`${group}.cane_burned_preharvest_fraction`)
                        ) || 0;

                    const area = watch("crop_generic.area", 0) || 0;
                    const areaUnit = watch("crop_generic.area__unit");
                    if (
                        !area ||
                        replantedAreaFraction < 0 ||
                        replantedAreaFraction > 100
                    ) {
                        return "-";
                    }
                    const areaUnitLabel = unitShortLabel(
                        getOption("area", areaUnit)
                    );
                    const replantedArea = (area * replantedAreaFraction) / 100;

                    return `${formatNumber(replantedArea, 2)} ${areaUnitLabel}`;
                },
            },
            {
                type: "check_info",
                widthLg: 2,
                styleBreakRowAfter: true,
                checkText: "Green cane harvest area",
                checkValue: (watch) => {
                    const replantedAreaFraction =
                        parseFloat(
                            watch(`${group}.cane_burned_preharvest_fraction`)
                        ) || 0;

                    const area = watch("crop_generic.area", 0) || 0;
                    const areaUnit = watch("crop_generic.area__unit");
                    if (
                        !area ||
                        replantedAreaFraction < 0 ||
                        replantedAreaFraction > 100
                    ) {
                        return "-";
                    }
                    const areaUnitLabel = unitShortLabel(
                        getOption("area", areaUnit)
                    );
                    const replantedArea =
                        (area * (100 - replantedAreaFraction)) / 100;

                    return `${formatNumber(replantedArea, 2)} ${areaUnitLabel}`;
                },
            },
            {
                type: "note",
                label: "Cane trash management after harvest",
                styleClassName: "fs-4",
                widthLg: 12,
            },
            {
                type: "number",
                name: `${group}.residue_burned_share`,
                label: "Share of cane trash that was burned",
                unit: "%",
                widthLg: 3,
            },
            {
                type: "number",
                name: `${group}.residue_left_on_field_share`,
                label: "Share of cane trash that was left on field",
                unit: "%",
                widthLg: 3,
            },
            {
                type: "number",
                name: `${group}.residue_composted_share`,
                label: "Share of cane trash that was composted",
                unit: "%",
                widthLg: 3,
            },
            {
                type: "number",
                name: `${group}.residue_energy_production_share`,
                label: "Share of cane trash that was used for energy production",
                unit: "%",
                widthLg: 3,
            },
            {
                type: "note",
                label: "**Warning**: Please make sure that the sum of weight fractions (product yield, coproduct yield, weight loss as waste and weight loss as water/vapor) from the raw material add up to 100%!",
                styleClassName: "text-danger",
                styleBreakRowAfter: true,
                condition: (watch) => {
                    const sum =
                        (parseFloat(watch(`${group}.residue_burned_share`)) ||
                            0) +
                        (parseFloat(
                            watch(`${group}.residue_left_on_field_share`)
                        ) || 0) +
                        (parseFloat(
                            watch(`${group}.residue_composted_share`)
                        ) || 0) +
                        (parseFloat(
                            watch(`${group}.residue_energy_production_share`)
                        ) || 0);

                    return sum != 0 && (sum <= 99 || sum >= 101);
                },
            },
        ],
    };

    return { group, data, ui };
};
