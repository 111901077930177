import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectCurrentViewLanguage } from "../../features/viewSlice";
import { deriveFormBadge, fieldOptionLabel } from "../../utils/forms";
import {
    fieldLabel,
    fieldCheckText,
    fieldHint,
    fieldInfo,
    fieldUnit,
} from "../../utils/i18nForms";
import { BaseInput } from "../elements/BaseInput";
import { BaseNumericInput } from "../elements/BaseNumericInput";
import { BaseSelect } from "../elements/BaseSelect";
import { BaseSelectHorizontalButtons } from "../elements/BaseSelectHorizontalButtons";
import { DangerousMarkdown } from "../elements/Markdown";
import { PostcodeField } from "../elements/PostcodeField";
import ModalRepeat from "./parts/ModalRepeat";
import ModalRepeatCarbonStockChanges from "./parts/ModalRepeatCarbonStockChanges";
import ModalRepeatCarbonStockChangesV2 from "./parts/ModalRepeatCarbonStockChangesV2";
import ModalRepeatCarbonStockChangesSimple from "./parts/ModalRepeatCarbonStockChangesSimple";

export default function FormFieldRenderer({
    dataSchema,
    uiSchema,
    defaultValues,
    form,
    context,
    sectionKey = null,
    fieldNamePrefix = "",
    parentForm = null,
}) {
    const {
        watch,
        formState: { errors },
    } = form || useFormContext();

    const currentLanguage = useSelector(selectCurrentViewLanguage);
    const fieldSectionKey = sectionKey ? sectionKey : uiSchema.name;

    function renderField(field, index, sectionKey) {
        if (field.condition && !field.condition(watch))
            return <span key={index}></span>;

        if (field.type == "text") {
            return (
                <BaseInput
                    name={fieldNamePrefix + field.name}
                    type={field.type}
                    asTextarea={field.asTextarea}
                    label={fieldLabel(field, sectionKey, { context })}
                    unit={fieldUnit(field, sectionKey)}
                    hint={fieldHint(field, sectionKey, { context })}
                    form={form}
                    info={fieldInfo(field, sectionKey)}
                />
            );
        }
        if (field.type == "number") {
            return (
                <BaseNumericInput
                    name={fieldNamePrefix + field.name}
                    type={field.type}
                    label={fieldLabel(field, sectionKey, { context })}
                    unit={fieldUnit(field, sectionKey)}
                    hint={fieldHint(field, sectionKey, { context })}
                    form={form}
                    info={fieldInfo(field, sectionKey)}
                    decimalScale={field?.decimalScale}
                />
            );
        }
        if (field.type == "postcode") {
            const parameters = field.parameters && field.parameters(watch);
            return (
                <PostcodeField
                    name={fieldNamePrefix + field.name}
                    label={fieldLabel(field, sectionKey, { context })}
                    hint={fieldHint(field, sectionKey)}
                    countryFieldName={parameters?.countryFieldName}
                    latitudeFieldName={parameters?.latitudeFieldName}
                    longitudeFieldName={parameters?.longitudeFieldName}
                    postcodeNotFoundFieldName={
                        parameters?.postcodeNotFoundFieldName
                    }
                    postcodeGeocodingFailedFieldName={
                        parameters?.postcodeGeocodingFailedFieldName
                    }
                    form={form}
                    context={context}
                />
            );
        }
        if (field.type == "select_one" && !field.styleSelectHorizontalButtons) {
            const options =
                (field.options instanceof Function
                    ? field.options(watch)
                    : field.options) || [];
            return (
                <BaseSelect
                    name={fieldNamePrefix + field.name}
                    label={fieldLabel(field, sectionKey, { context })}
                    hint={fieldHint(field, sectionKey, { context })}
                    options={options.map((o) => ({
                        value: o.name,
                        label: fieldOptionLabel(o, currentLanguage),
                    }))}
                    form={form}
                    info={fieldInfo(field, sectionKey)}
                />
            );
        }
        if (field.type == "select_one" && field.styleSelectHorizontalButtons) {
            const options =
                field.options instanceof Function
                    ? field.options(watch)
                    : field.options;
            return (
                <BaseSelectHorizontalButtons
                    name={fieldNamePrefix + field.name}
                    label={fieldLabel(field, sectionKey, { context })}
                    hint={fieldHint(field, sectionKey, { context })}
                    options={options.map((o) => ({
                        value: o.name,
                        label: fieldOptionLabel(o, currentLanguage),
                    }))}
                    form={form}
                    info={fieldInfo(field, sectionKey)}
                />
            );
        }
        if (field.type == "modal_repeat") {
            return (
                <ModalRepeat
                    dataSchema={dataSchema}
                    uiSchema={field}
                    defaultValues={defaultValues}
                    form={form}
                    sectionKey={sectionKey}
                    context={context}
                ></ModalRepeat>
            );
        }
        if (field.type == "model_repeat__carbon_stock_changes") {
            return (
                <ModalRepeatCarbonStockChanges
                    dataSchema={dataSchema}
                    uiSchema={field}
                    defaultValues={defaultValues}
                    form={form}
                    context={context}
                    parameters={field.parameters && field.parameters(watch)}
                ></ModalRepeatCarbonStockChanges>
            );
        }
        if (field.type == "model_repeat__carbon_stock_changes_v2") {
            return (
                <ModalRepeatCarbonStockChangesV2
                    dataSchema={dataSchema}
                    uiSchema={field}
                    defaultValues={defaultValues}
                    form={form}
                    sectionKey={sectionKey}
                    context={context}
                    parameters={field.parameters && field.parameters(watch)}
                ></ModalRepeatCarbonStockChangesV2>
            );
        }
        if (field.type == "model_repeat__carbon_stock_changes_simple") {
            return (
                <ModalRepeatCarbonStockChangesSimple
                    dataSchema={dataSchema}
                    uiSchema={field}
                    defaultValues={defaultValues}
                    form={form}
                    sectionKey={sectionKey}
                    context={context}
                    parameters={field.parameters && field.parameters(watch)}
                ></ModalRepeatCarbonStockChangesSimple>
            );
        }
        if (field.type == "note") {
            const badge = deriveFormBadge(field?.badge, watch);
            return (
                <p>
                    {badge && (
                        <span className="float-end fs-4 fw-normal">
                            <Badge pill bg={badge?.color || "primary"}>
                                {badge?.icon && (
                                    <i className={"bi bi-" + badge.icon}></i>
                                )}{" "}
                                {badge?.label}
                            </Badge>
                        </span>
                    )}
                    <DangerousMarkdown
                        text={fieldLabel(field, sectionKey, { context })}
                    />
                    {field.image && (
                        <img
                            src={field.image}
                            alt={field.label}
                            style={{ maxWidth: "100%" }}
                        />
                    )}
                </p>
            );
        }
        if (field.type == "check_info") {
            return;
        }
        return <p>Feldtyp nicht gefunden.</p>;
    }

    return (
        <Row>
            {uiSchema.children.map((field, i) => {
                if (!field) return;

                if (field.condition && !field.condition(watch))
                    return <Fragment key={i} />;

                let styleClass = field.styleClassName || "";
                if (!`${styleClass}`.includes("mb-")) styleClass += " mb-4";
                styleClass += " field-item";

                return (
                    <Fragment key={i}>
                        <Col lg={field.widthLg} className={styleClass}>
                            {renderField(field, i, fieldSectionKey)}
                            {field.checkText && (
                                <p className="pt-3">
                                    {fieldCheckText(field, fieldSectionKey)}
                                    <br />
                                    <span className="fs-5">
                                        {field.checkValue(
                                            watch,
                                            parentForm?.watch
                                        )}
                                    </span>
                                </p>
                            )}
                        </Col>
                        {field.styleBreakRowAfter && (
                            <div className="w-100"></div>
                        )}
                    </Fragment>
                );
            })}
        </Row>
    );
}
