import { array, number, object, string } from "yup";
import { cropLabel } from "../../../utils/crops";
import { formatNumber } from "../../../utils/format";
import { fieldOptionLabel } from "../../../utils/forms";
import { unitShortLabel } from "../../../utils/schemaUtils";

export const limingGenericSchema = function (
    { survey, surveyParams },
    { selectOptions, getOption, defaultUnit, convertUnit }
) {
    const group = "liming_generic";
    const unitSystem = surveyParams?.UNIT_SYSTEM;
    const enableTotalsInput =
        surveyParams?.ENABLE_FERTILIZER_TOTALS_INPUT === "true";

    const nutrientDisplayUnit = defaultUnit("weight_area_rate", unitSystem);

    function getNutrientRateKgHa({
        nutrient = null,
        fertilizerItem,
        area = null,
        areaUnit = null,
    }) {
        if (!fertilizerItem) return 0;
        const fertilizerName = fertilizerItem.type;
        const fertilizerProduct = getOption("lime_fertilizer", fertilizerName);

        let nutrientPercentage = 0;
        if (!nutrient) {
            nutrientPercentage = 100;
        } else {
            nutrientPercentage =
                parseFloat(fertilizerProduct?.nutrients?.[nutrient]) || 0;
        }

        if (!enableTotalsInput) {
            const applicationRate = fertilizerItem.application_rate;
            const rateUnit = fertilizerItem.application_rate__unit;
            const nutrientRate = (applicationRate * nutrientPercentage) / 100;
            const nutrientRateKgHa = convertUnit(
                "weight_area_rate",
                nutrientRate,
                rateUnit,
                "kg / ha"
            );
            return nutrientRateKgHa;
        } else {
            if (!area || !areaUnit) {
                return 0;
            }
            const applicationAmount = fertilizerItem.application_amount_total;
            const amountUnit = fertilizerItem.application_amount_total__unit;
            const applicationAmountKg = convertUnit(
                "weight",
                applicationAmount,
                amountUnit,
                "kg"
            );
            const areaHa = convertUnit("area", area, areaUnit, "ha");
            const nutrientRateKgHa =
                (applicationAmountKg * nutrientPercentage) / 100 / areaHa;

            return nutrientRateKgHa;
        }
    }
    function getNutrientRate({
        nutrient = null,
        fertilizerItem,
        area = null,
        areaUnit = null,
    }) {
        const nutrientRateKgHa = getNutrientRateKgHa({
            nutrient,
            fertilizerItem,
            area,
            areaUnit,
        });
        const nutrientRate = convertUnit(
            "weight_area_rate",
            nutrientRateKgHa,
            "kg / ha",
            nutrientDisplayUnit.name
        );
        const unit = fieldOptionLabel(nutrientDisplayUnit);
        return {
            rate: nutrientRate,
            unit: nutrientDisplayUnit.name,
            unitLabel: unit,
        };
    }

    const data = object({
        fertilizers: array().of(
            object({
                type: string().required().oneOfOptions("lime_fertilizer"),
                ...(!enableTotalsInput && {
                    application_rate: number().required().positive(),
                    application_rate__unit: string()
                        .required()
                        .oneOfOptions("weight_area_rate"),
                }),
                ...(enableTotalsInput && {
                    application_amount_total: number().required().positive(),
                    application_amount_total__unit: string()
                        .required()
                        .oneOfOptions("weight"),
                }),
                product_unit: string().required(),
            })
        ),
    });

    const defaults = {
        fertilizers: [],
        fertilizers__default: {
            application_rate__unit: defaultUnit("weight_area_rate", unitSystem)
                .name,
            application_amount_total__unit: defaultUnit("weight", unitSystem)
                .name,
            product_unit: "CaO",
        },
    };

    const ui = {
        type: "section",
        name: group,
        label: "Liming",
        label__de: "Kalkung",
        badge: enableTotalsInput ? "total" : "over_crop_rotation",
        children: [
            !enableTotalsInput && {
                type: "note",
                label: "This section refers to **liming on the entire arable land of your farm** in the given year, i.e., beyond the area of {{crop_name}}.",
                label__de:
                    "Dieser Abschnitt betrifft die **Kalkung auf der gesamten Ackerbaufläche Ihres Betriebs** im gegebenen Jahr, d.h. über die Fläche von {{crop_name}} hinaus.",
            },
            enableTotalsInput && {
                type: "note",
                label: "This section refers to all liming of {{crop_name}} in the given year.",
            },
            {
                type: "modal_repeat",
                name: `${group}.fertilizers`,
                label: "Liming",
                label__de: "Kalkdüngung",
                add_label: "Add liming application",
                add_label__de: "Kalkung hinzufügen",
                widthLg: 12,
                styleBreakRowAfter: true,
                tableHeaders: (language) =>
                    language == "de"
                        ? ["Düngemittel", "Menge (CaO)"]
                        : ["Type", "Application rate (CaO)"],
                tableRow: (
                    uiSchema,
                    fertilizerItem,
                    index,
                    language,
                    watch
                ) => {
                    const { rate, unitLabel } = getNutrientRate({
                        nutrient: null,
                        fertilizerItem: fertilizerItem,
                        area: watch(`crop_generic.area`, 0),
                        areaUnit: watch(`crop_generic.area__unit`),
                    });
                    return [
                        fieldOptionLabel(
                            getOption("lime_fertilizer", fertilizerItem?.type),
                            language
                        ),
                        `${formatNumber(rate, 2)} ${unitLabel} CaO`,
                    ];
                },
                tableFooter: (fertilizerList, watch) => {
                    let CaO_total = 0;
                    let unitLabel = fieldOptionLabel(nutrientDisplayUnit);

                    for (let fertilizerItem of fertilizerList) {
                        CaO_total += getNutrientRate({
                            nutrient: null,
                            fertilizerItem: fertilizerItem,
                            area: watch(`crop_generic.area`, 0),
                            areaUnit: watch(`crop_generic.area__unit`),
                        }).rate;
                    }

                    return [
                        `CaO: ${formatNumber(CaO_total, 0)} ${unitLabel}`,
                        "",
                    ];
                },
                children: [
                    !enableTotalsInput && {
                        type: "note",
                        label: "Please enter the **average liming rate over all arable lands of your farm** in the given year.",
                        label__de:
                            "Bitte geben Sie hier die **durchschnittliche Kalkungsrate über die gesamte Ackerbaufläche Ihres Betriebs** im gegebenen Jahr an.",
                        widthLg: 12,
                    },
                    enableTotalsInput && {
                        type: "note",
                        label: "Please enter the total applied amount for {{crop_name}} in the given year.",
                        widthLg: 12,
                    },
                    {
                        type: "select_one",
                        name: "type",
                        label: "Lime type",
                        label__de: "Kalkart",
                        options: selectOptions("lime_fertilizer"),
                        widthLg: 9,
                        styleBreakRowAfter: true,
                    },
                    !enableTotalsInput && {
                        type: "number",
                        name: "application_rate",
                        label: "Average amount of Calcium Oxide (CaO) applied over all arable lands",
                        label__de:
                            "Durchschnittliche Menge Calciumoxid (CaO) über gesamte Ackerbaufläche",
                        unit: selectOptions("weight_area_rate"),
                        widthLg: 5,
                    },
                    enableTotalsInput && {
                        type: "number",
                        name: "application_amount_total",
                        label: "Total amount of Calcium Oxide (CaO) applied to {{crop_name}}",
                        unit: selectOptions("weight"),
                        widthLg: 5,
                        checkText: "Calculated area rate",
                        checkValue: (watch, parentWatch) => {
                            const area = parentWatch(`crop_generic.area`, 0);
                            const areaUnit = parentWatch(
                                `crop_generic.area__unit`
                            );
                            const input = watch(`application_amount_total`, 0);
                            const inputUnit = watch(
                                `application_amount_total__unit`
                            );
                            const rate = input / area || 0;
                            const inputUnitLabel = unitShortLabel(
                                getOption("weight", inputUnit)
                            );
                            const areaUnitLabel = unitShortLabel(
                                getOption("area", areaUnit)
                            );
                            const unit = `${inputUnitLabel} CaO / ${areaUnitLabel}`;
                            return `${formatNumber(rate, 2)} ${unit}`;
                        },
                    },
                ],
            },
        ],
    };

    return { group, data, ui, defaults };
};
