// In this file, schema parts are put together to form different data entry form compositions.

import { object } from "yup";
import { buildSurveyParamIndex, mergeDefaults } from "../utils/schemaUtils";
import { carbonStockGenericSchema } from "./parts/carbon_stock/generic";
import { coverCropGenericSchema } from "./parts/cover_crop/generic";
import { coverCropSugarbeetSchema } from "./parts/cover_crop/sugarbeet";
import { cropGenericSchema } from "./parts/crop/generic";
import { cropProtectionGenericSchema } from "./parts/crop_protection/generic";
import { energyGenericSchema } from "./parts/energy/generic";
import { energySugarbeetSchema } from "./parts/energy/sugarbeet";
import { landManagementSimpleArableSchema } from "./parts/land_management/simple_arable";
import { landUseSimpleArableSchema } from "./parts/land_use/simple_arable";
import { limingGenericSchema } from "./parts/liming/generic";
import { limingSugarbeetSchema } from "./parts/liming/sugarbeet";
import { metaGenericSchema } from "./parts/meta";
import { mineralFertilizationGenericSchema } from "./parts/mineral_fertilizers/generic";
import { sectionMetaFields } from "./parts/misc/sectionMetaFields";
import { notesGenericSchema } from "./parts/notes";
import { organicFertilizationGenericSchema } from "./parts/organic_fertilizers/generic";
import { residueManagementArableSchema } from "./parts/residue_management/arable";
import { residueManagementGenericSchema } from "./parts/residue_management/generic";
import { residueManagementSugarbeetSchema } from "./parts/residue_management/sugarbeet";
import { residueManagementSugarcaneSchema } from "./parts/residue_management/sugarcane";
import { soilGenericSchema } from "./parts/soil/generic";
import { transportGenericSchema } from "./parts/transport/generic";
import { wasteWaterGenericSchema } from "./parts/waste_water/generic";
import { energyArableSchema } from "./parts/energy/arable";
import { cultivationSugarcaneSchema } from "./parts/cultivation/sugarcane";
import { transportArableSchema } from "./parts/transport/arable";
import { productOffFarmProcessingSchema } from "./parts/off_farm_processing/product";
import { energyOffFarmProcessingSchema } from "./parts/off_farm_processing/energy";
import { waterOffFarmProcessingSchema } from "./parts/off_farm_processing/water_wastewater";
import { packagingOffFarmProcessingSchema } from "./parts/off_farm_processing/packaging";
import { wasteOffFarmProcessingSchema } from "./parts/off_farm_processing/waste";
import { landManagementGenericSchema } from "./parts/land_management/generic";
import { landUseGenericSchema } from "./parts/land_use/generic";
import { transportInternationalSchema } from "./parts/transport/international";
import { supplyChainHerbalSchema } from "./parts/supply_chain/herbal";

export const FORM_MODULES = {
    meta_generic: [
        {
            schema: metaGenericSchema,
            version: 1,
        },
    ],
    crop_generic: [
        {
            schema: cropGenericSchema,
            version: 1,
        },
    ],
    cultivation_sugarcane: [
        {
            schema: cultivationSugarcaneSchema,
            version: 1,
        },
    ],
    cover_crop_generic: [
        {
            schema: coverCropGenericSchema,
            version: 1,
        },
    ],
    cover_crop_sugarbeet: [
        {
            schema: coverCropSugarbeetSchema,
            version: 1,
        },
    ],
    crop_protection_generic: [
        {
            schema: cropProtectionGenericSchema,
            version: 1,
        },
    ],
    energy_generic: [
        {
            schema: energyGenericSchema,
            version: 1,
        },
    ],
    energy_sugarbeet: [
        {
            schema: energySugarbeetSchema,
            version: 1,
        },
    ],
    carbon_stock_generic: [
        {
            schema: carbonStockGenericSchema,
            version: 1,
        },
    ],
    land_management_simple_arable: [
        {
            schema: landManagementSimpleArableSchema,
            version: 1,
        },
    ],
    land_use_simple_arable: [
        {
            schema: landUseSimpleArableSchema,
            version: 1,
        },
    ],
    liming_generic: [
        {
            schema: limingGenericSchema,
            version: 1,
        },
    ],
    liming_sugarbeet: [
        {
            schema: limingSugarbeetSchema,
            version: 1,
        },
    ],
    mineral_fertilization_generic: [
        {
            schema: mineralFertilizationGenericSchema,
            version: 1,
        },
    ],
    misc_sectionMetaFields: [
        {
            schema: sectionMetaFields,
            version: 1,
        },
    ],
    notes: [{ schema: notesGenericSchema, name: "notes", version: 1 }],
    organic_fertilization_generic: [
        {
            schema: organicFertilizationGenericSchema,
            version: 1,
        },
    ],
    residue_management_arable: [
        {
            schema: residueManagementArableSchema,
            version: 1,
        },
    ],
    residue_management_generic: [
        {
            schema: residueManagementGenericSchema,
            version: 1,
        },
    ],
    residue_management_sugarbeet: [
        {
            schema: residueManagementSugarbeetSchema,
            version: 1,
        },
    ],
    residue_management_sugarcane: [
        {
            schema: residueManagementSugarcaneSchema,
            version: 1,
        },
    ],
    soil_generic: [
        {
            schema: soilGenericSchema,
            version: 1,
        },
    ],
    transport_generic: [
        {
            schema: transportGenericSchema,
            version: 1,
        },
    ],
    waste_water_generic: [
        {
            schema: wasteWaterGenericSchema,
            version: 1,
        },
    ],
    energy_arable: [
        {
            schema: energyArableSchema,
            version: 1,
        },
    ],
    transport_arable: [
        {
            schema: transportArableSchema,
            version: 1,
        },
    ],
    product_off_farm_processing: [
        {
            schema: productOffFarmProcessingSchema,
            version: 1,
        },
    ],
    energy_off_farm_processing: [
        {
            schema: energyOffFarmProcessingSchema,
            version: 1,
        },
    ],
    water_off_farm_processing: [
        {
            schema: waterOffFarmProcessingSchema,
            version: 1,
        },
    ],
    packaging_off_farm_processing: [
        {
            schema: packagingOffFarmProcessingSchema,
            version: 1,
        },
    ],
    off_farm_processing_waste: [
        {
            schema: wasteOffFarmProcessingSchema,
            version: 1,
        },
    ],
    land_management_generic: [
        {
            schema: landManagementGenericSchema,
            version: 1,
        },
    ],
    land_use_generic: [
        {
            schema: landUseGenericSchema,
            version: 1,
        },
    ],
    transport_international: [
        {
            schema: transportInternationalSchema,
            version: 1,
        },
    ],
    supply_chain_herbal: [
        {
            schema: supplyChainHerbalSchema,
            version: 1,
        },
    ],
    notes_generic: [
        {
            schema: notesGenericSchema,
            version: 1,
        },
    ],
};

const schemaFromModules = function (
    modules,
    assessment,
    choiceIndex,
    schemaMethods
) {
    const surveyParams = buildSurveyParamIndex(
        assessment?.survey?.params || []
    );
    const surveyDefaults = assessment?.survey?.defaults || {};
    const disableSectionDataQuality =
        surveyParams?.DISABLE_FIELD_SECTION_DATA_QUALITY == "true";

    let uiSchema = [];
    let dataSchema = object({});
    const groups = [];
    const schemaDefaults = {};
    const moduleOptions = {
        surveyParams: surveyParams,
        farm: assessment?.farm,
        survey: assessment?.survey,
    };
    for (let module of modules) {
        let data, ui, defaults, group;
        ({ data, ui, defaults, group } = module.schema(
            moduleOptions,
            schemaMethods
        ));
        if (!disableSectionDataQuality) {
            ({ ui, data } = appendSectionMeta(
                group,
                { data, ui },
                moduleOptions,
                schemaMethods
            ));
        }

        uiSchema = uiSchema.concat([ui]);
        dataSchema = dataSchema.concat(object({ [group]: data }));
        if (defaults) schemaDefaults[group] = defaults;

        if (!(group in groups)) groups.push(group);
    }
    const defaults = mergeDefaults(schemaDefaults, surveyDefaults);
    return {
        uiSchema,
        dataSchema,
        defaultValues: defaults,
        sectionKeys: groups,
    };
};

function getModuleByNameVersion(name, version) {
    const modules = name in FORM_MODULES ? FORM_MODULES[name] : [];

    return modules.find((m) => m.version === version);
}

export function buildSchema(
    formDefinitionModules,
    assessment,
    choiceIndex,
    schemaMethods
) {
    if (!formDefinitionModules || !formDefinitionModules.modules) return null;

    const modules = formDefinitionModules.modules.map((m) =>
        getModuleByNameVersion(m?.name, m?.version)
    );

    return schemaFromModules(modules, assessment, choiceIndex, schemaMethods);
}

function appendSectionMeta(group, { data, ui }, partOptions, schemaMethods) {
    if (!ui.type == "section") return { data, ui };

    const { data: metaData, ui: metaUi } = sectionMetaFields(
        group,
        partOptions,
        schemaMethods
    );

    return {
        data: data.concat(metaData),
        ui: { ...ui, children: ui.children.concat(metaUi.children) },
    };
}
