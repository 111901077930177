import { array, number, object, string } from "yup";
import { formatNumber } from "../../../utils/format";
import { fieldOptionLabel } from "../../../utils/forms";
import {
    getCustomNPKContents,
    monthHalfOptions,
} from "../../../utils/schemaUtils";

export const mineralFertilizationGenericSchema = function (
    { survey, surveyParams },
    {
        selectOptions,
        getOption,
        defaultUnit,
        convertUnit,
        testFertilizerNRate,
        testCustomFertilizerTotalPercentage,
    }
) {
    const group = "mineral_fertilization_generic";
    const unitSystem = surveyParams?.UNIT_SYSTEM;
    const hideApplicationDate =
        surveyParams?.DISABLE_MINERAL_FERTILIZER_APPLICATION_DATE === "true";
    const showUreaseInhibitor =
        surveyParams?.ENABLE_MINERAL_FERTILIZER_UREASE_INHIBITOR === "true";
    const enableTotalsInput =
        surveyParams?.ENABLE_FERTILIZER_TOTALS_INPUT === "true";

    const nRateMinKgHa = 0;
    const nRateMaxKgHa =
        parseFloat(surveyParams?.MINERAL_FERTILIZERS_TOTAL_N_MAX_KG_HA) || 1000;

    const monthHalfOptionsList = monthHalfOptions(
        "fertilization_date",
        survey?.year,
        18
    );

    const nutrientDisplayUnit = defaultUnit("weight_area_rate", unitSystem);

    function getNutrientRateKgHa({
        nutrient = null,
        fertilizerItem,
        area = null,
        areaUnit = null,
    }) {
        if (!fertilizerItem) return 0;
        const fertilizerName = fertilizerItem.type;
        const fertilizerProduct = getOption(
            "mineral_fertilizer",
            fertilizerName
        );

        let nutrientPercentage = 0;
        if (!nutrient) {
            nutrientPercentage = 100;
        } else if (fertilizerProduct?.name == "Custom") {
            const customNpkContents = getCustomNPKContents(
                fertilizerItem,
                convertUnit
            );
            nutrientPercentage = customNpkContents?.[nutrient] || 0;
        } else {
            nutrientPercentage =
                parseFloat(fertilizerProduct?.nutrients?.[nutrient]) || 0;
        }

        if (!enableTotalsInput) {
            const applicationRate = fertilizerItem.application_rate;
            const rateUnit = fertilizerItem.application_rate__unit;
            const nutrientRate = (applicationRate * nutrientPercentage) / 100;
            const nutrientRateKgHa = convertUnit(
                "weight_area_rate",
                nutrientRate,
                rateUnit,
                "kg / ha"
            );
            return nutrientRateKgHa;
        } else {
            if (!area || !areaUnit) {
                return 0;
            }
            const applicationAmount = fertilizerItem.application_amount_total;
            const amountUnit = fertilizerItem.application_amount_total__unit;
            const applicationAmountKg = convertUnit(
                "weight",
                applicationAmount,
                amountUnit,
                "kg"
            );
            const areaHa = convertUnit("area", area, areaUnit, "ha");
            const nutrientRateKgHa =
                (applicationAmountKg * nutrientPercentage) / 100 / areaHa;

            return nutrientRateKgHa;
        }
    }
    function getNutrientRate({
        nutrient = null,
        fertilizerItem,
        area = null,
        areaUnit = null,
    }) {
        const nutrientRateKgHa = getNutrientRateKgHa({
            nutrient,
            fertilizerItem,
            area,
            areaUnit,
        });
        const nutrientRate = convertUnit(
            "weight_area_rate",
            nutrientRateKgHa,
            "kg / ha",
            nutrientDisplayUnit.name
        );
        const unit = fieldOptionLabel(nutrientDisplayUnit);
        return {
            rate: nutrientRate,
            unit: nutrientDisplayUnit.name,
            unitLabel: unit,
        };
    }

    const data = object({
        fertilizers: array()
            .of(
                object({
                    type: string()
                        .required()
                        .oneOfOptions("mineral_fertilizer"),
                    ...(!enableTotalsInput && {
                        application_rate: number().required().positive(),
                        application_rate__unit: string()
                            .required()
                            .oneOfOptions("weight_area_rate"),
                    }),
                    ...(enableTotalsInput && {
                        application_amount_total: number()
                            .required()
                            .positive(),
                        application_amount_total__unit: string()
                            .required()
                            .oneOfOptions("weight"),
                    }),
                    nitrification_inhibitor_applied: string().oneOfOptions(
                        "yes_no",
                        true
                    ),
                    ...(showUreaseInhibitor && {
                        urease_inhibitor_applied: string().oneOfOptions(
                            "yes_no",
                            true
                        ),
                    }),
                    application_method: string()
                        .required()
                        .oneOfOptions("fertilization_method_minerals"),
                    ...(!hideApplicationDate && {
                        application_date: string()
                            .notRequired()
                            .oneOfOptions(monthHalfOptionsList, true),
                    }),
                    production_region: string()
                        .required()
                        .oneOfOptions("fertilizer_production_region")
                        .test({
                            name: "production-region-matches-fertilizer",
                            test: function (regionName) {
                                const fertilizer = getOption(
                                    "mineral_fertilizer",
                                    this.parent.type
                                );
                                if (!fertilizer?.production_regions)
                                    return false;
                                return fertilizer.production_regions.some(
                                    (option) => option.name === regionName
                                );
                            },
                            message:
                                "Production region must be valid for the selected fertilizer type",
                        }),
                    custom_N_ammonium_share: number()
                        .notRequired()
                        .nanToUndefined()
                        .min(0)
                        .max(100)
                        .test({
                            name: "custom-N-sum-max-100",
                            test: function (value, yupContext) {
                                const custom_N_ammonium_share = value;
                                const custom_N_nitrate_share =
                                    yupContext?.parent?.custom_N_nitrate_share;
                                const custom_N_urea_share =
                                    yupContext?.parent?.custom_N_urea_share;
                                if (
                                    custom_N_ammonium_share +
                                        custom_N_nitrate_share +
                                        custom_N_urea_share >
                                    100
                                )
                                    return false;

                                return true;
                            },
                            message:
                                "Ammonia, nitrate and urea combined may not exceed 100%.",
                        }),
                    custom_N_nitrate_share: number()
                        .notRequired()
                        .nanToUndefined()
                        .min(0)
                        .max(100),
                    custom_N_urea_share: number()
                        .notRequired()
                        .nanToUndefined()
                        .min(0)
                        .max(100),
                    custom_P_share: number()
                        .notRequired()
                        .nanToUndefined()
                        .min(0)
                        .max(100),
                    custom_P_share__unit: string()
                        .required()
                        .oneOfOptions("phosphorous_nutrient"),
                    custom_K_share: number()
                        .notRequired()
                        .nanToUndefined()
                        .min(0)
                        .max(100),
                    custom_K_share__unit: string()
                        .required()
                        .oneOfOptions("potassium_nutrient"),
                })
            )
            .test({
                name: "mineral_n_rate_test",
                test: testFertilizerNRate(
                    nRateMinKgHa,
                    nRateMaxKgHa,
                    getOption,
                    getNutrientRateKgHa,
                    "mineral_fertilizer",
                    "crop_generic.area"
                ),
                message:
                    "Nitrogen fertilization may not be less than ${min} or more than ${max} kg/ha.",
                params: { min: nRateMinKgHa, max: nRateMaxKgHa },
            })
            .test({
                name: "custom_npk_percentage_test",
                test: testCustomFertilizerTotalPercentage(
                    getOption,
                    "mineral_fertilizer"
                ),
                message:
                    "Sum of ingredients of custom N-P-K fertilizers may not exceed 100%.",
                params: { min: nRateMinKgHa, max: nRateMaxKgHa },
            }),
    });

    const defaults = {
        fertilizers: [],
        fertilizers__default: {
            application_rate__unit: defaultUnit("weight_area_rate", unitSystem)
                .name,
            application_amount_total__unit: defaultUnit("weight", unitSystem)
                .name,
            custom_P_share__unit: defaultUnit(
                "phosphorous_nutrient",
                unitSystem
            ).name,
            custom_K_share__unit: defaultUnit("potassium_nutrient", unitSystem)
                .name,
        },
    };

    const ui = {
        type: "section",
        name: group,
        label: "Mineral fertilizers",
        label__de: "Mineraldüngung",
        badge: enableTotalsInput ? "total" : "per_area",
        children: [
            {
                type: "modal_repeat",
                name: `${group}.fertilizers`,
                label: "Mineral fertilizer",
                label__de: "Mineralische Düngemittel",
                add_label: "Add fertilizer",
                add_label__de: "Düngemittel hinzufügen",
                styleBreakRowAfter: true,
                tableHeaders: (language) =>
                    language == "de"
                        ? ["Düngemittel", "Menge"]
                        : ["Type", "Application rate"],
                tableRow: (
                    uiSchema,
                    fertilizerItem,
                    index,
                    language,
                    watch
                ) => {
                    const { rate, unitLabel } = getNutrientRate({
                        nutrient: null,
                        fertilizerItem: fertilizerItem,
                        area: watch(`crop_generic.area`, 0),
                        areaUnit: watch(`crop_generic.area__unit`),
                    });
                    return [
                        fieldOptionLabel(
                            getOption(
                                "mineral_fertilizer",
                                fertilizerItem?.type
                            ),
                            language
                        ),
                        `${formatNumber(rate, 2)} ${unitLabel}`,
                    ];
                },
                tableFooter: (fertilizerList, watch) => {
                    let N_total = 0;
                    let P2O5_total = 0;
                    let K2O_total = 0;
                    let unitLabel = fieldOptionLabel(nutrientDisplayUnit);

                    for (let fertilizerItem of fertilizerList) {
                        N_total += getNutrientRate({
                            nutrient: "n",
                            fertilizerItem: fertilizerItem,
                            area: watch(`crop_generic.area`, 0),
                            areaUnit: watch(`crop_generic.area__unit`),
                        }).rate;
                        P2O5_total += getNutrientRate({
                            nutrient: "p2o5",
                            fertilizerItem: fertilizerItem,
                            area: watch(`crop_generic.area`, 0),
                            areaUnit: watch(`crop_generic.area__unit`),
                        }).rate;
                        K2O_total += getNutrientRate({
                            nutrient: "k2o",
                            fertilizerItem: fertilizerItem,
                            area: watch(`crop_generic.area`, 0),
                            areaUnit: watch(`crop_generic.area__unit`),
                        }).rate;
                    }

                    return [
                        `N: ${formatNumber(
                            N_total,
                            0
                        )} ${unitLabel} | P₂O₅: ${formatNumber(
                            P2O5_total,
                            0
                        )} ${unitLabel} | K₂O: ${formatNumber(
                            K2O_total,
                            0
                        )} ${unitLabel}`,
                    ];
                },
                children: [
                    {
                        type: "select_one",
                        name: "type",
                        label: "Fertilizer",
                        label__de: "Düngemittel",
                        options: selectOptions("mineral_fertilizer"),
                        widthLg: 9,
                        styleBreakRowAfter: true,
                    },
                    !enableTotalsInput && {
                        type: "number",
                        name: "application_rate",
                        label: "Product amount applied",
                        label__de: "Ausbringmenge des Produkts",
                        unit: selectOptions("weight_area_rate"),
                        widthLg: 5,
                    },
                    enableTotalsInput && {
                        type: "number",
                        name: "application_amount_total",
                        label: "Total product amount applied",
                        hint: "Applied product over entire area of {{crop_name}}",
                        unit: selectOptions("weight"),
                        widthLg: 5,
                    },
                    {
                        type: "select_one",
                        name: "nitrification_inhibitor_applied",
                        label: "Nitrification inhibitor applied?",
                        label__de: "Nitrifikations-Hemmer verwendet?",
                        options: selectOptions("yes_no"),
                        styleSelectHorizontalButtons: true,
                        styleBreakRowAfter: showUreaseInhibitor ? false : true,
                        widthLg: 3,
                    },
                    showUreaseInhibitor && {
                        type: "select_one",
                        name: "urease_inhibitor_applied",
                        label: "Urease inhibitor applied?",
                        label__de: "Urease-Hemmer verwendet?",
                        options: selectOptions("yes_no"),
                        styleSelectHorizontalButtons: true,
                        styleBreakRowAfter: true,
                        widthLg: 3,
                    },
                    {
                        type: "check_info",
                        widthLg: 4,
                        condition: (watch) => watch("type") != "Custom",
                        checkText__de: "Stickstoff N",
                        checkText: "Nitrogen N",
                        checkValue: (watch, parentWatch) => {
                            const { rate, unitLabel } = getNutrientRate({
                                nutrient: "n",
                                fertilizerItem: watch(),
                                area: parentWatch(`crop_generic.area`, 0),
                                areaUnit: parentWatch(
                                    `crop_generic.area__unit`
                                ),
                            });

                            return `${formatNumber(
                                rate || 0,
                                2
                            )} ${unitLabel} `;
                        },
                    },
                    {
                        type: "check_info",
                        widthLg: 4,
                        condition: (watch) => watch("type") != "Custom",
                        checkText__de: "Phosphor P₂O₅",
                        checkText: "Phosphorous P₂O₅",
                        checkValue: (watch, parentWatch) => {
                            const { rate, unitLabel } = getNutrientRate({
                                nutrient: "p2o5",
                                fertilizerItem: watch(),
                                area: parentWatch(`crop_generic.area`, 0),
                                areaUnit: parentWatch(
                                    `crop_generic.area__unit`
                                ),
                            });

                            return `${formatNumber(
                                rate || 0,
                                2
                            )} ${unitLabel} `;
                        },
                    },
                    {
                        type: "check_info",
                        widthLg: 4,
                        condition: (watch) => watch("type") != "Custom",
                        checkText__de: "Kalium K₂O",
                        checkText: "Potassium K₂O",
                        checkValue: (watch, parentWatch) => {
                            const { rate, unitLabel } = getNutrientRate({
                                nutrient: "k2o",
                                fertilizerItem: watch(),
                                area: parentWatch(`crop_generic.area`, 0),
                                areaUnit: parentWatch(
                                    `crop_generic.area__unit`
                                ),
                            });

                            return `${formatNumber(
                                rate || 0,
                                2
                            )} ${unitLabel} `;
                        },
                    },
                    {
                        type: "select_one",
                        name: "application_method",
                        label: "Application method",
                        label__de: "Ausbringungsmethode",
                        options: selectOptions("fertilization_method_minerals"),
                        widthLg: 6,
                    },
                    !hideApplicationDate && {
                        type: "select_one",
                        name: "application_date",
                        label: "Date of application",
                        label__de: "Zeitpunkt der Ausbringung",
                        options: monthHalfOptionsList,
                        widthLg: 6,
                    },
                    {
                        type: "select_one",
                        name: "production_region",
                        label: "Fertilizer production region",
                        label__de: "Herstellungsregion des Düngers",
                        options: (watch) => {
                            const fertilizerTypeName = watch("type");
                            if (!fertilizerTypeName) return [];
                            const fertilizer = getOption(
                                "mineral_fertilizer",
                                fertilizerTypeName
                            );
                            const fertilizerProductionRegions =
                                fertilizer.production_regions;
                            return fertilizerProductionRegions;
                        },
                        widthLg: 6,
                    },
                    {
                        type: "note",
                        label: "Nutrient contents of custom NPK fertilizer",
                        label__de: "Nährstoffgehalte des eigenen NPK-Düngers",
                        condition: (watch) => watch("type") == "Custom",
                        styleClassName: "fs-4 mt-4",
                        widthLg: 12,
                    },
                    {
                        type: "number",
                        name: "custom_N_ammonium_share",
                        label: "Ammonium N content",
                        label__de: "Ammonium-N-Gehalt",
                        unit: "% N",
                        condition: (watch) => watch("type") == "Custom",
                        widthLg: 3,
                    },
                    {
                        type: "number",
                        name: "custom_N_nitrate_share",
                        label: "Nitrate N content",
                        label__de: "Nitrat-N-Gehalt",
                        unit: "% N",
                        condition: (watch) => watch("type") == "Custom",
                        widthLg: 3,
                    },
                    {
                        type: "number",
                        name: "custom_N_urea_share",
                        label: "Urea N content",
                        label__de: "Harnstoff-N-Gehalt",
                        unit: "% N",
                        condition: (watch) => watch("type") == "Custom",
                        widthLg: 3,
                    },
                    {
                        type: "check_info",
                        widthLg: 3,
                        condition: (watch) => watch("type") == "Custom",
                        styleBreakRowAfter: true,
                        checkText__de: "Gesamt-Stickstoffgehalt",
                        checkText: "Total nitrogen content",
                        checkValue: (watch) => {
                            const nitrogen =
                                (parseFloat(watch("custom_N_ammonium_share")) ||
                                    0) +
                                (parseFloat(watch("custom_N_nitrate_share")) ||
                                    0) +
                                (parseFloat(watch("custom_N_urea_share")) || 0);

                            return `${formatNumber(nitrogen || 0, 2)} %`;
                        },
                    },
                    {
                        type: "number",
                        name: "custom_P_share",
                        label: "Phosphorous content",
                        label__de: "Phosphor-Gehalt",
                        unit: selectOptions("phosphorous_nutrient"),
                        condition: (watch) => watch("type") == "Custom",
                        widthLg: 4,
                    },
                    {
                        type: "number",
                        name: "custom_K_share",
                        label: "Potassium content",
                        label__de: "Kalium-Gehalt",
                        unit: selectOptions("potassium_nutrient"),
                        condition: (watch) => watch("type") == "Custom",
                        widthLg: 4,
                    },
                ],
            },
        ],
    };

    return { group, data, ui, defaults };
};
