import { array, number, object, string } from "yup";
import { formatNumber } from "../../../utils/format";
import { fieldOptionLabel } from "../../../utils/forms";

export const limingSugarbeetSchema = function (
    { survey, surveyParams },
    { selectOptions, getOption, defaultUnit, convertUnit }
) {
    const group = "liming_sugarbeet";
    const unitSystem = surveyParams?.UNIT_SYSTEM;

    const data = object({
        fertilizers: array().of(
            object({
                type: string().required().oneOfOptions("lime_fertilizer"),
                application_rate: number().required().positive(),
                application_rate__unit: string()
                    .required()
                    .oneOfOptions("weight_area_rate"),
                product_unit: string().required(),
                crop_count_until_next_liming: number()
                    .required()
                    .integer()
                    .min(1)
                    .max(10),
            })
        ),
    });

    const nutrientDisplayUnit = defaultUnit("weight_area_rate", unitSystem);

    const defaults = {
        fertilizers: [],
        fertilizers__default: {
            application_rate__unit: defaultUnit("weight_area_rate", unitSystem)
                .name,
            product_unit: "CaO",
        },
    };

    const ui = {
        type: "section",
        name: group,
        label: "Liming",
        label__de: "Kalkung",
        badge: "over_crop_rotation",
        children: [
            {
                type: "note",
                label: "This section refers to **liming on the entire arable land of your farm** in the given year, i.e., beyond the area of {{crop_name}}.",
            },
            {
                type: "modal_repeat",
                name: `${group}.fertilizers`,
                label: "Liming",
                add_label: "Add liming application",
                widthLg: 12,
                styleBreakRowAfter: true,
                tableHeaders: (language) =>
                    language == "de"
                        ? ["Düngemittel", "Menge (CaO)"]
                        : ["Type", "Application rate (CaO)"],
                tableRow: (uiSchema, itemData, index, language) => [
                    fieldOptionLabel(
                        getOption("lime_fertilizer", itemData?.type),
                        language
                    ),
                    `${formatNumber(itemData?.application_rate, 2)} ${
                        itemData?.application_rate__unit
                    }`,
                ],
                tableFooter: (data) => {
                    let cao_total = 0;

                    for (let row of data) {
                        const fertilizer = getOption(
                            "lime_fertilizer",
                            row?.type
                        );
                        const applicationRate = row?.application_rate || 0;
                        const rateUnit = row?.application_rate__unit;
                        const convertedApplicationRate = convertUnit(
                            "weight_area_rate",
                            applicationRate,
                            rateUnit,
                            nutrientDisplayUnit.name
                        );

                        cao_total += convertedApplicationRate || 0;
                    }

                    const unitLabel = nutrientDisplayUnit.label_short;
                    return [`CaO: ${formatNumber(cao_total, 0)} ${unitLabel}`];
                },
                children: [
                    {
                        type: "note",
                        label: "Please enter the **average liming rate over all arable lands of your farm** in the given year.\n\n-----\n**Example**:\n- Application rate of Carbokalk (27% CaO): 100 t Carbokalk/year\n- equals 27 t CaO/year\n- Area on which Carbokalk was applied: 50 ha\n- Application rate CaO per hectare: 540 kg CaO/ha",
                        widthLg: 12,
                    },
                    {
                        type: "select_one",
                        name: "type",
                        label: "Lime type",
                        options: selectOptions("lime_fertilizer"),
                        widthLg: 9,
                        styleBreakRowAfter: true,
                    },
                    {
                        type: "number",
                        name: "application_rate",
                        label: "Average amount of Calcium Oxide (CaO) applied over all arable lands",
                        unit: selectOptions("weight_area_rate"),
                        widthLg: 5,
                        styleBreakRowAfter: true,
                    },
                    {
                        type: "number",
                        name: "crop_count_until_next_liming",
                        label: "Number of main crops until next liming",
                        hint: "Example: Winter barley/ catch crop/ sugar beet (liming for sugar beet)/ winter wheat: Main crops = 3",
                        widthLg: 6,
                    },
                ],
            },
        ],
    };

    return { group, data, ui, defaults };
};
