import { array, number, object, string } from "yup";
import { formatNumber } from "../../../utils/format";
import { fieldOptionLabel } from "../../../utils/forms";
import { monthHalfOptions } from "../../../utils/schemaUtils";

export const organicFertilizationGenericSchema = function (
    { surveyParams, survey },
    { selectOptions, getOption, defaultUnit, convertUnit, testFertilizerNRate }
) {
    const group = "organic_fertilization_generic";
    const unitSystem = surveyParams?.UNIT_SYSTEM;
    const hideApplicationDate =
        surveyParams?.DISABLE_ORGANIC_FERTILIZER_APPLICATION_DATE === "true";
    const enableTotalsInput =
        surveyParams?.ENABLE_FERTILIZER_TOTALS_INPUT === "true";

    const nRateMinKgHa = 0;
    const nRateMaxKgHa =
        parseFloat(surveyParams?.ORGANIC_FERTILIZERS_TOTAL_N_MAX_KG_HA) || 1000;

    const monthHalfOptionsList = monthHalfOptions(
        "fertilization_date",
        survey?.year,
        18
    );

    const nutrientDisplayUnit = defaultUnit("weight_area_rate", unitSystem);

    function getNutrientRateKgHa({
        nutrient = null,
        fertilizerItem,
        area = null,
        areaUnit = null,
    }) {
        if (!fertilizerItem) return 0;
        const fertilizerName = fertilizerItem.type;
        const fertilizerProduct = getOption(
            "organic_fertilizer",
            fertilizerName
        );

        let nutrientPercentage = 0;
        if (!nutrient) {
            nutrientPercentage = 100;
        } else {
            nutrientPercentage =
                parseFloat(fertilizerProduct?.nutrients?.[nutrient]) || 0;
        }

        if (!enableTotalsInput) {
            const applicationRate = fertilizerItem.application_rate;
            const rateUnit = fertilizerItem.application_rate__unit;
            const nutrientRate = (applicationRate * nutrientPercentage) / 100;
            const nutrientRateKgHa = convertUnit(
                "weight_area_rate",
                nutrientRate,
                rateUnit,
                "kg / ha"
            );
            return nutrientRateKgHa;
        } else {
            if (!area || !areaUnit) {
                return 0;
            }
            const applicationAmount = fertilizerItem.application_amount_total;
            const amountUnit = fertilizerItem.application_amount_total__unit;
            const applicationAmountKg = convertUnit(
                "weight",
                applicationAmount,
                amountUnit,
                "kg"
            );
            const areaHa = convertUnit("area", area, areaUnit, "ha");
            const nutrientRateKgHa =
                (applicationAmountKg * nutrientPercentage) / 100 / areaHa;

            return nutrientRateKgHa;
        }
    }
    function getNutrientRate({
        nutrient = null,
        fertilizerItem,
        area = null,
        areaUnit = null,
    }) {
        const nutrientRateKgHa = getNutrientRateKgHa({
            nutrient,
            fertilizerItem,
            area,
            areaUnit,
        });
        const nutrientRate = convertUnit(
            "weight_area_rate",
            nutrientRateKgHa,
            "kg / ha",
            nutrientDisplayUnit.name
        );
        const unit = fieldOptionLabel(nutrientDisplayUnit);
        return {
            rate: nutrientRate,
            unit: nutrientDisplayUnit.name,
            unitLabel: unit,
        };
    }

    const data = object({
        fertilizers: array()
            .of(
                object({
                    type: string()
                        .required()
                        .oneOfOptions("organic_fertilizer"),
                    ...(!enableTotalsInput && {
                        application_rate: number().required().positive(),
                        application_rate__unit: string()
                            .required()
                            .oneOfOptions("weight_area_rate"),
                    }),
                    ...(enableTotalsInput && {
                        application_amount_total: number()
                            .required()
                            .positive(),
                        application_amount_total__unit: string()
                            .required()
                            .oneOfOptions("weight"),
                    }),
                    nitrification_inhibitor_applied: string()
                        .required()
                        .oneOfOptions("yes_no"),
                    application_method: string()
                        .required()
                        .oneOfOptions("fertilization_method"),
                    ...(!hideApplicationDate && {
                        application_date: string()
                            .notRequired()
                            .oneOfOptions(monthHalfOptionsList, true),
                    }),
                    transport_distance: number().required().min(0),
                    transport_distance__unit: string()
                        .required()
                        .oneOfOptions("distance"),
                })
            )
            .test({
                name: "organic_n_rate",
                test: testFertilizerNRate(
                    nRateMinKgHa,
                    nRateMaxKgHa,
                    getOption,
                    getNutrientRateKgHa,
                    "mineral_fertilizer",
                    "crop_generic.area"
                ),
                message:
                    "Nitrogen fertilization may not be less than ${min} or more than ${max} kg/ha.",
                params: { min: nRateMinKgHa, max: nRateMaxKgHa },
            }),
    });

    const defaults = {
        fertilizers: [],
        fertilizers__default: {
            application_rate__unit: defaultUnit("weight_area_rate", unitSystem)
                .name,
            application_amount_total__unit: defaultUnit("weight", unitSystem)
                .name,
            transport_distance__unit: defaultUnit("distance", unitSystem).name,
        },
    };

    const ui = {
        type: "section",
        name: group,
        name: group,
        label: "Organic fertilizers",
        label__de: "Organische Düngung",
        badge: enableTotalsInput ? "total" : "per_area",
        children: [
            {
                type: "modal_repeat",
                name: `${group}.fertilizers`,
                label: "Organic fertilizers",
                label__de: "Organische Düngemittel",
                add_label: "Add fertilizer",
                add_label__de: "Düngemittel hinzufügen",
                styleBreakRowAfter: true,
                tableHeaders: (language) =>
                    language == "de"
                        ? ["Düngemittel", "Menge"]
                        : ["Type", "Application rate"],
                tableRow: (
                    uiSchema,
                    fertilizerItem,
                    index,
                    language,
                    watch
                ) => {
                    const { rate, unitLabel } = getNutrientRate({
                        nutrient: null,
                        fertilizerItem: fertilizerItem,
                        area: watch(`crop_generic.area`, 0),
                        areaUnit: watch(`crop_generic.area__unit`),
                    });
                    return [
                        fieldOptionLabel(
                            getOption(
                                "organic_fertilizer",
                                fertilizerItem?.type
                            ),
                            language
                        ),
                        `${formatNumber(rate, 2)} ${unitLabel}`,
                    ];
                },
                tableFooter: (fertilizerList, watch) => {
                    let N_total = 0;
                    let unitLabel = fieldOptionLabel(nutrientDisplayUnit);

                    for (let fertilizerItem of fertilizerList) {
                        N_total += getNutrientRate({
                            nutrient: "n",
                            fertilizerItem: fertilizerItem,
                            area: watch(`crop_generic.area`, 0),
                            areaUnit: watch(`crop_generic.area__unit`),
                        }).rate;
                    }

                    return [`N: ${formatNumber(N_total, 0)} ${unitLabel}`, ""];
                },
                children: [
                    {
                        type: "select_one",
                        name: "type",
                        label: "Fertilizer",
                        label__de: "Düngemittel",
                        options: selectOptions("organic_fertilizer"),
                        widthLg: 8,
                        styleBreakRowAfter: true,
                    },
                    !enableTotalsInput && {
                        type: "number",
                        name: "application_rate",
                        label: "Application rate of material",
                        label__de: "Ausbringmenge des Materials",
                        unit: selectOptions("weight_area_rate"),
                        widthLg: 6,
                    },
                    enableTotalsInput && {
                        type: "number",
                        name: "application_amount_total",
                        label: "Total amount of material applied",
                        hint: "Applied material over entire area of {{crop_name}}",
                        unit: selectOptions("weight"),
                        widthLg: 5,
                    },
                    {
                        type: "select_one",
                        name: "nitrification_inhibitor_applied",
                        label: "Nitrification inhibitor applied?",
                        label__de: "Nitrifikations-Hemmer verwendet?",
                        options: selectOptions("yes_no"),
                        styleSelectHorizontalButtons: true,
                        styleBreakRowAfter: true,
                        widthLg: 6,
                    },
                    {
                        type: "check_info",
                        widthLg: 6,
                        styleBreakRowAfter: true,
                        checkText__de: "Stickstoff N",
                        checkText: "Nitrogen N",
                        checkValue: (watch, parentWatch) => {
                            const { rate, unitLabel } = getNutrientRate({
                                nutrient: "n",
                                fertilizerItem: watch(),
                                area: parentWatch(`crop_generic.area`, 0),
                                areaUnit: parentWatch(
                                    `crop_generic.area__unit`
                                ),
                            });

                            return `${formatNumber(
                                rate || 0,
                                2
                            )} ${unitLabel} `;
                        },
                    },
                    {
                        type: "select_one",
                        name: "application_method",
                        label: "Application method",
                        label__de: "Ausbringungsmethode",
                        options: selectOptions("fertilization_method"),
                        widthLg: 6,
                    },
                    !hideApplicationDate && {
                        type: "select_one",
                        name: "application_date",
                        label: "Date of application",
                        label__de: "Zeitpunkt der Ausbringung",
                        options: monthHalfOptionsList,
                        widthLg: 6,
                        styleBreakRowAfter: true,
                    },
                    {
                        type: "number",
                        name: "transport_distance",
                        label: "Average transport distance from origin to field",
                        label__de:
                            "Durchschnittliche Transportdistanz vom Ursprung zum Feld",
                        unit: selectOptions("distance"),
                        widthLg: 7,
                    },
                ],
            },
        ],
    };

    return { group, data, ui, defaults };
};
