import { object, string, number } from "yup";
import { unitShortLabel } from "../../../utils/schemaUtils";
import { formatNumber } from "../../../utils/format";

export const cultivationSugarcaneSchema = function (
    {},
    { selectOptions, getOption }
) {
    const group = "cultivation_sugarcane";

    const data = object({
        land_preparation_method: string()
            .required()
            .oneOfOptions("sugarcane_land_preparation_method"),
        input_application_method: string()
            .required()
            .oneOfOptions("sugarcane_input_method"),
        irrigation_method: string()
            .required()
            .oneOfOptions("sugarcane_irrigation_method"),
        harvesting_method: string()
            .required()
            .oneOfOptions("sugarcane_harvest_method"),
        replanted_area_fraction: number().min(0).max(100),
    });

    const ui = {
        type: "section",
        name: group,
        label: "Sugarcane farming practice",
        children: [
            {
                type: "note",
                label: "Replanting & land preparation",
                styleClassName: "fs-4 mt-4 mb-0",
                widthLg: 12,
            },
            {
                type: "number",
                name: `${group}.replanted_area_fraction`,
                label: "Share of area with plant cane in the given timeframe",
                hint: "Include replanted and newly established cane fields, no ratoon areas.",
                unit: "%",
                widthLg: 3,
            },
            {
                type: "check_info",
                widthLg: 2,
                checkText: "Replanted area",
                checkValue: (watch) => {
                    const replantedAreaFraction =
                        parseFloat(watch(`${group}.replanted_area_fraction`)) ||
                        0;

                    const area = watch("crop_generic.area", 0) || 0;
                    const areaUnit = watch("crop_generic.area__unit");
                    if (
                        !area ||
                        replantedAreaFraction < 0 ||
                        replantedAreaFraction > 100
                    ) {
                        return "-";
                    }
                    const areaUnitLabel = unitShortLabel(
                        getOption("area", areaUnit)
                    );
                    const replantedArea = (area * replantedAreaFraction) / 100;

                    return `${formatNumber(replantedArea, 2)} ${areaUnitLabel}`;
                },
            },
            {
                type: "check_info",
                widthLg: 2,
                styleBreakRowAfter: true,
                checkText: "Ratoon area",
                checkValue: (watch) => {
                    const replantedAreaFraction =
                        parseFloat(watch(`${group}.replanted_area_fraction`)) ||
                        0;

                    const area = watch("crop_generic.area", 0) || 0;
                    const areaUnit = watch("crop_generic.area__unit");
                    if (
                        !area ||
                        replantedAreaFraction < 0 ||
                        replantedAreaFraction > 100
                    ) {
                        return "-";
                    }
                    const areaUnitLabel = unitShortLabel(
                        getOption("area", areaUnit)
                    );
                    const replantedArea =
                        (area * (100 - replantedAreaFraction)) / 100;

                    return `${formatNumber(replantedArea, 2)} ${areaUnitLabel}`;
                },
            },
            {
                type: "select_one",
                name: `${group}.land_preparation_method`,
                label: "Land preparation method",
                hint: "Main method for preparing the soil for replanting",
                options: selectOptions("sugarcane_land_preparation_method"),
                widthLg: 4,
            },
            {
                type: "note",
                label: "Farming practice",
                styleClassName: "fs-4 mt-4 mb-0",
                widthLg: 12,
            },
            {
                type: "select_one",
                name: `${group}.input_application_method`,
                label: "Input application method",
                hint: "Main method for applying inputs like fertilizers and pesticides",
                options: selectOptions("sugarcane_input_method"),
                widthLg: 4,
            },
            {
                type: "select_one",
                name: `${group}.irrigation_method`,
                label: "Irrigation method",
                hint: "Main method for irrigation, or no irrigation",
                options: selectOptions("sugarcane_irrigation_method"),
                widthLg: 4,
            },
            {
                type: "select_one",
                name: `${group}.harvesting_method`,
                label: "Harvesting method",
                hint: "Main method for harvesting the cane",
                options: selectOptions("sugarcane_harvest_method"),
                widthLg: 4,
                styleBreakRowAfter: true,
            },
        ],
    };

    return { group, data, ui };
};
