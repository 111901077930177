import { number, object, string } from "yup";
import { unitShortLabel } from "../../../utils/schemaUtils";
import { formatNumber } from "../../../utils/format";

export const cropProtectionGenericSchema = function (
    { surveyParams },
    { selectOptions, defaultUnit, testTotalsAgainstWeightAreaRate, getOption }
) {
    const group = "crop_protection_generic";
    const unitSystem = surveyParams?.UNIT_SYSTEM;

    const pesticideEachMaxKgHa =
        parseFloat(surveyParams?.PESTICIDES_EACH_MAX_KG_HA) || 100;

    const enableTotals =
        surveyParams?.ENABLE_CROP_PROTECTION_TOTALS_INPUT == "true";

    const data = object({
        ...(!enableTotals && {
            amount_herbicides: number()
                .min(0)
                .nanToUndefined()
                .notRequired()
                .limitsWithUnit("weight_area_rate", "amount_herbicides", {
                    unit: "kg / ha",
                    min: 0,
                    max: pesticideEachMaxKgHa,
                }),
            amount_herbicides__unit: string()
                .notRequired()
                .oneOfOptions("weight_area_rate"),
            amount_insecticides: number()
                .min(0)
                .nanToUndefined()
                .notRequired()
                .limitsWithUnit("weight_area_rate", "amount_insecticides", {
                    unit: "kg / ha",
                    min: 0,
                    max: pesticideEachMaxKgHa,
                }),
            amount_insecticides__unit: string()
                .notRequired()
                .oneOfOptions("weight_area_rate"),
            amount_fungicides: number()
                .min(0)
                .nanToUndefined()
                .notRequired()
                .limitsWithUnit("weight_area_rate", "amount_fungicides", {
                    unit: "kg / ha",
                    min: 0,
                    max: pesticideEachMaxKgHa,
                }),
            amount_fungicides__unit: string()
                .notRequired()
                .oneOfOptions("weight_area_rate"),
            amount_other_pesticides: number()
                .min(0)
                .nanToUndefined()
                .notRequired()
                .limitsWithUnit("weight_area_rate", "amount_other_pesticides", {
                    unit: "kg / ha",
                    min: 0,
                    max: pesticideEachMaxKgHa,
                }),
            amount_other_pesticides__unit: string()
                .notRequired()
                .oneOfOptions("weight_area_rate"),
        }),
        ...(enableTotals && {
            amount_herbicides_total: number()
                .min(0)
                .nanToUndefined()
                .notRequired()
                .test({
                    name: "crop_protection_herbicides_absolute_limits",
                    test: testTotalsAgainstWeightAreaRate({
                        rateMinTHa: 0,
                        rateMaxTHa: pesticideEachMaxKgHa / 1000,
                        totalWeightFieldName: "amount_herbicides_total",
                        totalAreaFieldName: "crop_generic.area",
                    }),
                    message:
                        "Crop protection product amount may not be higher than ${max} kg/ha.",
                    params: { min: 0, max: pesticideEachMaxKgHa },
                }),
            amount_herbicides_total__unit: string()
                .notRequired()
                .oneOfOptions("weight"),
            amount_insecticides_total: number()
                .min(0)
                .nanToUndefined()
                .notRequired()
                .test({
                    name: "crop_protection_insecticides_absolute_limits",
                    test: testTotalsAgainstWeightAreaRate({
                        rateMinTHa: 0,
                        rateMaxTHa: pesticideEachMaxKgHa / 1000,
                        totalWeightFieldName: "amount_insecticides_total",
                        totalAreaFieldName: "crop_generic.area",
                    }),
                    message:
                        "Crop protection product amount may not be higher than ${max} kg/ha.",
                    params: { min: 0, max: pesticideEachMaxKgHa },
                }),
            amount_insecticides_total__unit: string()
                .notRequired()
                .oneOfOptions("weight"),
            amount_fungicides_total: number()
                .min(0)
                .nanToUndefined()
                .notRequired()
                .test({
                    name: "crop_protection_fungicides_absolute_limits",
                    test: testTotalsAgainstWeightAreaRate({
                        rateMinTHa: 0,
                        rateMaxTHa: pesticideEachMaxKgHa / 1000,
                        totalWeightFieldName: "amount_fungicides_total",
                        totalAreaFieldName: "crop_generic.area",
                    }),
                    message:
                        "Crop protection product amount may not be higher than ${max} kg/ha.",
                    params: { min: 0, max: pesticideEachMaxKgHa },
                }),
            amount_fungicides_total__unit: string()
                .notRequired()
                .oneOfOptions("weight"),
            amount_other_pesticides_total: number()
                .min(0)
                .nanToUndefined()
                .notRequired()
                .test({
                    name: "crop_protection_other_absolute_limits",
                    test: testTotalsAgainstWeightAreaRate({
                        rateMinTHa: 0,
                        rateMaxTHa: pesticideEachMaxKgHa / 1000,
                        totalWeightFieldName: "amount_other_pesticides_total",
                        totalAreaFieldName: "crop_generic.area",
                    }),
                    message:
                        "Crop protection product amount may not be higher than ${max} kg/ha.",
                    params: { min: 0, max: pesticideEachMaxKgHa },
                }),
            amount_other_pesticides_total__unit: string()
                .notRequired()
                .oneOfOptions("weight"),
        }),
    });

    const defaults = {
        amount_herbicides__unit: defaultUnit("weight_area_rate", unitSystem)
            .name,
        amount_insecticides__unit: defaultUnit("weight_area_rate", unitSystem)
            .name,
        amount_fungicides__unit: defaultUnit("weight_area_rate", unitSystem)
            .name,
        amount_other_pesticides__unit: defaultUnit(
            "weight_area_rate",
            unitSystem
        ).name,
        amount_herbicides_total__unit: defaultUnit("weight", unitSystem).name,
        amount_insecticides_total__unit: defaultUnit("weight", unitSystem).name,
        amount_fungicides_total__unit: defaultUnit("weight", unitSystem).name,
        amount_other_pesticides_total__unit: defaultUnit("weight", unitSystem)
            .name,
    };

    const ui = {
        type: "section",
        name: group,
        label: "Crop protection",
        label__de: "Pflanzenschutz",
        badge: enableTotals ? "total" : "per_area",
        children: [
            {
                type: "note",
                label: "Please provide the average product amounts, not the amounts of active ingredients. For liquid products, 1 litre may be assumed to equal 1 kilogram.",
                label__de:
                    "Bitte durchschnittliche Produktmengen angeben, nicht die Menge der aktiven Substanzen. Bei flüssigen Produkten kann 1 L als 1 kg angenommen werden.",
                widthLg: 12,
            },
            !enableTotals && {
                type: "number",
                name: `${group}.amount_herbicides`,
                label: "How much herbicide was applied on average?",
                label__de:
                    "Wie viel Herbizid wurde durchschnittlich ausgebracht?",
                unit: selectOptions("weight_area_rate").filter(
                    (u) => u.magnitude_spec == "small"
                ),
                widthLg: 4,
            },
            !enableTotals && {
                type: "number",
                name: `${group}.amount_insecticides`,
                label: "How much insecticide was applied on average?",
                label__de:
                    "Wie viel Insektizid wurde durchschnittlich ausgebracht?",
                unit: selectOptions("weight_area_rate").filter(
                    (u) => u.magnitude_spec == "small"
                ),
                widthLg: 4,
                styleBreakRowAfter: true,
            },
            !enableTotals && {
                type: "number",
                name: `${group}.amount_fungicides`,
                label: "How much fungicide was applied on average?",
                label__de:
                    "Wie viel Fungizid wurde durchschnittlich ausgebracht?",
                unit: selectOptions("weight_area_rate").filter(
                    (u) => u.magnitude_spec == "small"
                ),
                widthLg: 4,
            },
            !enableTotals && {
                type: "number",
                name: `${group}.amount_other_pesticides`,
                label: "How much of other pesticides was applied on average?",
                label__de:
                    "Wie viel sonstige Pflanzenschutzmittel wurden durchschnittlich ausgebracht?",
                unit: selectOptions("weight_area_rate").filter(
                    (u) => u.magnitude_spec == "small"
                ),
                widthLg: 4,
            },
            enableTotals && {
                type: "number",
                name: `${group}.amount_herbicides_total`,
                label: "How much herbicide was applied to {{crop_name}} in total?",
                unit: selectOptions("weight"),
                widthLg: 4,
                checkText: "Calculated area rate",
                checkValue: (watch) => {
                    const area = watch(`crop_generic.area`, 0);
                    const areaUnit = watch(`crop_generic.area__unit`);
                    const input = watch(`${group}.amount_herbicides_total`, 0);
                    const inputUnit = watch(
                        `${group}.amount_herbicides_total__unit`
                    );
                    const rate = input / area || 0;
                    const inputUnitLabel = unitShortLabel(
                        getOption("weight", inputUnit)
                    );
                    const areaUnitLabel = unitShortLabel(
                        getOption("area", areaUnit)
                    );
                    const unit = `${inputUnitLabel} / ${areaUnitLabel}`;
                    return `${formatNumber(rate, 2)} ${unit}`;
                },
            },
            enableTotals && {
                type: "number",
                name: `${group}.amount_insecticides_total`,
                label: "How much insecticide was applied to {{crop_name}} in total?",
                unit: selectOptions("weight"),
                widthLg: 4,
                checkText: "Calculated area rate",
                checkValue: (watch) => {
                    const area = watch(`crop_generic.area`, 0);
                    const areaUnit = watch(`crop_generic.area__unit`);
                    const input = watch(
                        `${group}.amount_insecticides_total`,
                        0
                    );
                    const inputUnit = watch(
                        `${group}.amount_insecticides_total__unit`
                    );
                    const rate = input / area || 0;
                    const inputUnitLabel = unitShortLabel(
                        getOption("weight", inputUnit)
                    );
                    const areaUnitLabel = unitShortLabel(
                        getOption("area", areaUnit)
                    );
                    const unit = `${inputUnitLabel} / ${areaUnitLabel}`;
                    return `${formatNumber(rate, 2)} ${unit}`;
                },
                styleBreakRowAfter: true,
            },
            enableTotals && {
                type: "number",
                name: `${group}.amount_fungicides_total`,
                label: "How much fungicide was applied to {{crop_name}} in total?",
                unit: selectOptions("weight"),
                widthLg: 4,
                checkText: "Calculated area rate",
                checkValue: (watch) => {
                    const area = watch(`crop_generic.area`, 0);
                    const areaUnit = watch(`crop_generic.area__unit`);
                    const input = watch(`${group}.amount_fungicides_total`, 0);
                    const inputUnit = watch(
                        `${group}.amount_fungicides_total__unit`
                    );
                    const rate = input / area || 0;
                    const inputUnitLabel = unitShortLabel(
                        getOption("weight", inputUnit)
                    );
                    const areaUnitLabel = unitShortLabel(
                        getOption("area", areaUnit)
                    );
                    const unit = `${inputUnitLabel} / ${areaUnitLabel}`;
                    return `${formatNumber(rate, 2)} ${unit}`;
                },
            },
            enableTotals && {
                type: "number",
                name: `${group}.amount_other_pesticides_total`,
                label: "How much of other pesticides was applied to {{crop_name}} in total?",
                unit: selectOptions("weight"),
                widthLg: 4,
                checkText: "Calculated area rate",
                checkValue: (watch) => {
                    const area = watch(`crop_generic.area`, 0);
                    const areaUnit = watch(`crop_generic.area__unit`);
                    const input = watch(
                        `${group}.amount_other_pesticides_total`,
                        0
                    );
                    const inputUnit = watch(
                        `${group}.amount_other_pesticides_total__unit`
                    );
                    const rate = input / area || 0;
                    const inputUnitLabel = unitShortLabel(
                        getOption("weight", inputUnit)
                    );
                    const areaUnitLabel = unitShortLabel(
                        getOption("area", areaUnit)
                    );
                    const unit = `${inputUnitLabel} / ${areaUnitLabel}`;
                    return `${formatNumber(rate, 2)} ${unit}`;
                },
            },
        ],
    };

    return { group, data, ui, defaults };
};
