export function mapEmissionSource(s, i18n) {
    return i18n._(`form_results.data_labels.${s}`);
}

export function ghgInventorySummary(
    evaluation,
    emission_details,
    i18n,
    language = null
) {
    if (!evaluation || !emission_details) return {};

    const areaHa = evaluation?.area_ha;
    const yieldT = evaluation?.product_kg / 1000;

    let combinedResults = {};
    for (let detail of emission_details) {
        const key = detail.emission_source;
        if (!(key in combinedResults)) {
            combinedResults[key] = {
                hectare: 0,
                tonne: 0,
                label: mapEmissionSource(detail.emission_source, i18n),
                details: [],
            };
        }
        const per_ha =
            (detail.emissions_co2e_total_kg - detail.removals_co2e_total_kg) /
            areaHa;
        const per_tonne =
            (detail.emissions_co2e_total_kg - detail.removals_co2e_total_kg) /
            yieldT;

        combinedResults[key].hectare = combinedResults[key].hectare + per_ha;
        combinedResults[key].tonne = combinedResults[key].tonne + per_tonne;

        // find label translations, if available
        let detail_label = detail.emission_source_detail;
        if (
            detail?.enriched_detail_meta?.labels &&
            language in detail?.enriched_detail_meta?.labels
        ) {
            detail_label = detail?.enriched_detail_meta?.labels[language];
        }

        if (detail.emission_source_detail) {
            combinedResults[key].details.push({
                hectare: per_ha,
                tonne: per_tonne,
                label: detail_label,
            });
        }
    }

    return combinedResults;
}

export function ghgInventoryTotal(summary, unitKey, includeRemovals = false) {
    let total = 0;
    for (let rKey of Object.keys(summary)) {
        const r = summary[rKey];
        if (rKey == "co_product") continue; // do not sum co-products
        if (r[unitKey] < 0 && !includeRemovals) continue;
        total += r[unitKey];
    }
    return total;
}

export function totalEmissions(evaluation, includeRemovals = false) {
    if (!evaluation) return null;

    const emissionsTotal = evaluation?.total_emissions || 0;
    const removalsTotal = evaluation?.total_removals || 0;

    if (includeRemovals) {
        return emissionsTotal - removalsTotal;
    }
    return emissionsTotal;
}

export function totalEmissionsPerHectare(evaluation, includeRemovals = false) {
    if (!evaluation) return null;

    const areaHa = evaluation?.area_ha;
    const emissionsTotal = totalEmissions(evaluation, includeRemovals);
    let emissionsPerHectare = null;
    if (areaHa && areaHa > 0) emissionsPerHectare = emissionsTotal / areaHa;

    return emissionsPerHectare;
}
export function totalEmissionsPerTonne(evaluation, includeRemovals = false) {
    if (!evaluation) return null;

    const yieldT = evaluation?.product_kg / 1000;
    const emissionsTotal = totalEmissions(evaluation, includeRemovals);
    let emissionsPerTonne = null;
    if (yieldT && yieldT > 0) emissionsPerTonne = emissionsTotal / yieldT;

    return emissionsPerTonne;
}
